import React from "react"
import { Helmet } from "react-helmet"

import {
  Container,
  HeadlineSuperhero,
  Paragraph,
} from "src/components/primitives"

import { HeaderSuperhero } from "src/components/layouts"

import { useDarkTheme } from "src/context/theme"

import SEO from "src/components/seo"

const Page404 = ({ data }) => {
  
  useDarkTheme()
  
  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "theme-dark",
        }}
      />
      <SEO title="Uh oh..." description="This is your Pilot speaking. We've landed on the wrong page. Don't worry, it's not terminal." />
      <article>
        <Container>
          <HeaderSuperhero>
            <HeadlineSuperhero>Uh oh...</HeadlineSuperhero>
          </HeaderSuperhero>

          <Paragraph>
            This is your Pilot speaking. We've landed on the wrong page. Don't worry, it's not terminal.
          </Paragraph>
        </Container>
      </article>
    </>
  )
}

export default Page404
